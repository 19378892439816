body {
  margin: 0;
}

.app {
  min-height: 100vh;
  color: #333;
  background-color: #fafafa;
  flex-direction: column;
  margin: 0;
  font-family: Noto Sans, sans-serif;
  font-size: calc(10px + 2vmin);
  display: flex;
}

.section {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.background {
  background-image: url("background.fa3c06ba.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.white {
  color: #fff;
}

.navbar {
  z-index: 1;
  color: #fff;
  background-color: #8b00b5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  font-family: "M PLUS 1 Code", sans-serif;
  font-size: 20px;
  display: flex;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.navbar-brand {
  float: left;
  color: #fff;
  justify-content: center;
  font-size: 2.5rem;
  text-decoration: none;
  display: flex;
}

.navbar-links div {
  justify-content: center;
  display: flex;
}

.navbar-links a {
  color: #fff;
  justify-content: center;
  margin: 0 10px;
  font-size: 1.5rem;
  text-decoration: none;
}

.section-title {
  justify-content: center;
  font-family: "M PLUS 1 Code", sans-serif;
  font-size: 2.5rem;
  text-decoration: none;
  display: flex;
}

.side-by-side {
  flex-wrap: wrap;
  justify-content: space-evenly;
  display: flex;
}

.left, .right {
  width: 40%;
  min-width: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
}

.button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #8b00b5;
  border: none;
  border-radius: 12px;
  margin: 4px 2px;
  padding: 15px 32px;
  font-family: "M PLUS 1 Code", sans-serif;
  font-size: 1.5rem;
  text-decoration: none;
  display: inline-block;
}

.footer {
  color: #fff;
  background-color: #8b00b5;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-family: "M PLUS 1 Code", sans-serif;
  display: flex;
}

.footer-list {
  list-style-type: none;
}

.footer-list li {
  margin: 0 10px;
}

.footer-list a {
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
}

.form-group {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.form-group input, textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
  padding: 10px;
  font-family: Noto Sans, sans-serif;
  font-size: 1.5rem;
}

.error {
  color: red;
  font-family: Noto Sans, sans-serif;
  font-size: 1rem;
}

/*# sourceMappingURL=index.3de83ffa.css.map */
