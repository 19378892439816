body {
    margin: 0;
}

.app {
    background-color: #fafafa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    color: #333;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.background {
    background-image: url('./assets/images/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.white {
    color: #fff;
}

.navbar {
    background-color: #8b00b5;
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 1;
    color: white;
    font-size: 20px;
    padding: 20px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    font-family: 'M PLUS 1 Code', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    float: left;
    font-size: 2.5rem;
    color: white;
    text-decoration: none;
    justify-content: center;
    display: flex;
}

.navbar-links div {
    justify-content: center;
    display: flex;
}

.navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    margin: 0 10px;
    justify-content: center;
}

.section-title {
    font-size: 2.5rem;
    text-decoration: none;
    justify-content: center;
    display: flex;
    font-family: 'M PLUS 1 Code', sans-serif;
}

.side-by-side {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.left, .right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
    min-width: 300px;
    padding: 10px;
}

.button {
    background-color: #8b00b5;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5rem;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    font-family: 'M PLUS 1 Code', sans-serif;
}

.footer {
    background-color: #8b00b5;
    color: white;
    padding: 20px;
    font-family: 'M PLUS 1 Code', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
}

.footer-list {
    list-style-type: none;
}

.footer-list li {
    margin: 0 10px;
}

.footer-list a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}

.form-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-group input, textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1.5rem;
    font-family: 'Noto Sans', sans-serif;
}

.error {
    color: red;
    font-size: 1rem;
    font-family: 'Noto Sans', sans-serif;
}